/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useState, useEffect, useCallback, useContext } from "react";
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from "react-router-dom";
import {
  Table,
  NFTHolderNav,
  useNavigate,
  Message,
  Feedback,
  Card,
  Link,
  Grid,
  BattlefieldConfig,
  ViewContext
} from "components/lib";

import Style from "../nft.tailwind.js";
//import { useWeb3React } from "@web3-react/core";
import Axios from "axios";

//Solana stuff
//import { useConnection, useWallet } from "@solana/wallet-adapter-react";

export function NFTHolderConfig(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const viewContext = useContext(ViewContext);

  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()

  let pathSplit = location.pathname.split("/");
  const nft_id = pathSplit[pathSplit.length - 1];

  // fetch
  //const nftData = useAPI('/api/nft/' + nft_id);
  const [nftData, setNFTData] = useState(null);
  //const [unAssignedXP, setUnAssignedXP] = useState(0);
  //const { active, account, chainId } = useWeb3React();
  //const [existingAccount, setAccount] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const [advanced, setAdvanced] = useState(false);

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  //console.log("Solana wallet: " + publicKey);

  const getDetails = useCallback(async () => {
    setDetailsLoading(true);
    //console.log("Getting Details");
    const res = await Axios.get(`/api/nft/${nft_id}?filterEvents=true`);
    //console.log(res);
    if (!res.data.success) {
      console.log("Unauthorized");
      navigate("/home");
    } else {
      //let unAssignedXP = (res.data.data.nftData.xp_raw - res.data.data.nftData.xp).toLocaleString();
      //setUnAssignedXP(unAssignedXP);
      setNFTData(res.data.data);

      let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
      if (serverURL.includes("localhost")) {
        serverURL = "http://" + serverURL + ":8080";
      } else {
        //serverURL = "https://" + serverURL;
        serverURL = "https://game.nftscribe.io";
      }

      const resCredits = await Axios.get(`/api/shop/credits`);
      console.log(resCredits.data.data);
      setCurrentUserCreditInfo(resCredits.data.data);

      setDetailsLoading(false);
    }
  }, [nft_id, navigate]);

  useEffect(() => {
    //console.log(viewContext);
    setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  useEffect(() => {
    //console.log("Initial load");
    const id = nft_id;
    if (id) getDetails(id);
  }, [getDetails, nft_id]);

  return (
    <Fragment>
      <NFTHolderNav
        nft_id={nft_id}
        nft_collection={nftData ? nftData.nftData.collection : null}
        center={!advanced}
      />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
        <Card className={Style.tinyRow}>
          <strong>Metaverse Credits: </strong>{" "}
          {currentUserCreditInfo && currentUserCreditInfo.creditBalance
            ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
            : "--"}
          &nbsp;&nbsp;&nbsp;
          <Link
            title="Transaction History"
            target="_same"
            url="/shop/history"
            text="History"
          />
          &nbsp;&nbsp;&nbsp;
          <Link
            title="Need More Metaverse Credits? Get some here!"
            target="_blank"
            url="/shop/token/play"
            text="Get More Credits"
          />
        </Card>

        {!detailsLoading && nftData && (
          <BattlefieldConfig nftData={nftData.nftData} />
        )}
      </div>
      <Feedback />
    </Fragment>
  );
}
