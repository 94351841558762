/***
*
*   ONBOARDING
*   Flow to help users set up the app, accepts multiple views/steps 
*   On finish/cancel, the user will be marked as onboarded
*
*   PROPS
*   views: array of views to render containing keys: 
*     name, description and component to render
*
**********/

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Animate, CheckList, Button, Logo, useNavigate, Event, Card, Link, Grid, TabView, WavesConfig, BossConfig } from 'components/lib';
import Style from './battlefield.tailwind.js';

export function BattlefieldConfig(props){

  //console.log(props);
  //const navigate = useNavigate();
  //const hash = window.location.hash;
  const [battlefieldCustomizations, setBattlefieldCustomizations] = useState(null);
  const [battlefieldLink, setBattlefieldLink] = useState(null);
  const [waves, setWaves] = useState([]);
  const [currentWaveIndex, setCurrentWaveIndex] = useState(0);
  const [enemyTypeCounts, setEnemyTypeCounts] = useState({
    Minion: 0,
    Warrior: 0,
    Mage: 0,
    Healer: 0,
    Beast: 0
  });

  const difficultyMultipliers = {
    'Easy': 0.5,
    'Normal': 1,
    'Hard': 1.5,
    'Elite': 3,
    'Boss': 10
  };

  const enemyTypes = [
    {name: 'Minion', difficulty: 0.5},
    {name: 'Warrior', difficulty: 1},
    {name: 'Mage', difficulty: 2},
    {name: 'Healer', difficulty: 3},
    {name: 'Beast', difficulty: 4},
  ];

  //const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');



  useEffect(() => {
    console.log("Initial load battlefield");
    let bfSlug = props.nftData.slug;
    let nftName = props.nftData.nickname;
    if(bfSlug == null){
      if(nftName == null){
        nftName = props.nftData.name;
      }
      bfSlug = slugify(nftName);
    }

    async function getCustomizations() {
      const res = await Axios.get(`api/customization/battlefield/${props.nftData.id}`);
      console.log(res.data.data);
      let existingCustomizations = res.data.data;
      //if(existingCustomizations.length > 0){
        setBattlefieldCustomizations(existingCustomizations);
        
      //}
      //setCollections(res.data.data.collections);
      //forceUpdate();
    }

    getCustomizations();
        
    setBattlefieldLink("https://app.nftscribe.io/game/" + bfSlug);

    //console.log(slugify('Battlefield #4'));
  }, [])

  const addWave = () => {
    const newWave = { 
      num_enemies: 0, 
      overall_difficulty: 0, 
      total_difficulty: 0, 
      enemies: [] 
    };
    setWaves([...waves, newWave]);
  };

 // Function to add enemy to current wave
  const addEnemyToWave = (type, difficulty, difficultyValue) => {
    
    const typeName = type.name;
    const typeDifficulty = type.difficulty;
    const overall_difficulty = typeDifficulty * difficultyMultipliers[difficulty];
    console.log(`addEnemyToWave difficulty: ${difficulty}   difficultyValue: ${difficultyValue} overall_difficulty: ${overall_difficulty} `)
    const newCount = enemyTypeCounts[typeName] + 1;
    const updatedCounts = { ...enemyTypeCounts, [typeName]: newCount };
    
    setEnemyTypeCounts(updatedCounts);

    const newEnemy = {
      typeName,
      difficulty,
      difficultyValue,
      overall_difficulty,
      displayName: `${typeName} ${newCount}`  // Default display name
    };

    const updatedWave = {
      ...currentWave,
      enemies: [...currentWave.enemies, newEnemy],
      num_enemies: currentWave.enemies.length + 1,
      total_difficulty: currentWave.total_difficulty + overall_difficulty,
    };

    // Update current wave
    setWaves(waves.map((wave, index) => index === currentWaveIndex ? updatedWave : wave));
  };

  const removeEnemy = (enemyIndex) => {
    const enemyToRemove = currentWave.enemies[enemyIndex];
    const updatedEnemies = currentWave.enemies.filter((_, index) => index !== enemyIndex);
    
    // Decrement the count for the removed enemy's type
    const updatedCounts = { ...enemyTypeCounts };
    updatedCounts[enemyToRemove.type] -= 1;
    setEnemyTypeCounts(updatedCounts);
  
    const updatedWave = {
      ...currentWave,
      num_enemies: currentWave.num_enemies - 1,
      total_difficulty: currentWave.total_difficulty - enemyToRemove.overall_difficulty,
      enemies: updatedEnemies
    };
  
    // Update current wave
    setWaves(waves.map((wave, index) => index === currentWaveIndex ? updatedWave : wave));
  };
  



  // Function to update enemy in the current wave
  const updateEnemy = (enemyIndex, field, value) => {
    const updatedEnemy = { ...currentWave.enemies[enemyIndex], [field]: value };

    // Update difficulty multiplier based on selected difficulty
    

    if (field === 'difficulty') {
      updatedEnemy.overall_difficulty = updatedEnemy.difficultyValue * difficultyMultipliers[value];
    }

    const updatedEnemies = currentWave.enemies.map((e, index) => index === enemyIndex ? updatedEnemy : e);

    // Recalculate total difficulty for the wave
    const newTotalDifficulty = updatedEnemies.reduce((acc, enemy) => acc + enemy.overall_difficulty, 0);

    const updatedWave = {
      ...currentWave,
      enemies: updatedEnemies,
      num_enemies: updatedEnemies.length,
      total_difficulty: newTotalDifficulty
    };

    // Update the current wave
    setWaves(waves.map((wave, index) => index === currentWaveIndex ? updatedWave : wave));
  };


  const saveConfiguration = () => {
    // Your saving logic here
    console.log("Sending to server:", JSON.stringify(waves));
  };

  const currentWave = waves[currentWaveIndex] || {};

  return (
    <Animate type='pop-center'>
      <div className={ Style.onboarding }>


        <section className={ Style.sidebar }>
          <div className='text-center py-0 text-white'>{props.nftData.nickname ? `"${props.nftData.nickname}" ${props.nftData.name}` : props.nftData.name}</div>
    
          {/*<Card className={Style.smallRow}>
          <strong>Battlefield Link:&nbsp;&nbsp;</strong>
            {battlefieldLink && 
              <Link 
                url={battlefieldLink} 
                text={battlefieldLink}
                target={"_new"} 
              />
            }
          </Card>
          */}
          {props.nftData && battlefieldCustomizations && (
            <TabView center dark name='Battlefield Customization' labels={['Boss', 'Waves']}>
              <Card dark700><BossConfig nftData={props.nftData} customizations={battlefieldCustomizations} /></Card>
              <Card dark700 ><WavesConfig nftData={props.nftData} hideMenu /></Card>
            </TabView>
          )}

        </section>
    </div>
    </Animate>
  );
}