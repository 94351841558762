const Style = {
    collection: 'p0 text-xs text-normal-tight',
    collectionTitle: 'p0 text-s text-normal-tight font-semibold',
    nftname: 'p0 text-xs font-semibold',
    nft: 'mx-auto text-center min-h-400 shadow-lg',
    nftNoButton: 'mx-auto text-center min-h-360 shadow-lg',
    nftLocked: 'mx-auto text-center min-h-345 shadow-lg',
    nftholder: 'mx-auto text-center min-h-390 shadow-lg',
    nftCollectionCard: 'mx-auto text-center min-h-275 shadow-lg',
    nftStats: 'mx-auto min-h-390 shadow-lg',
    nftButton: 'px-5 py-1!important mx-auto text-center mt-1',
    nftImage: 'cursor-pointer max-h-80 sm:max-h-52 mx-auto',
    nftImageSmall: 'cursor-pointer max-h-80 sm:max-h-40 mx-auto',
    nftCollectionImage: 'max-h-32 mx-auto',
    nftStatCardCenter: 'text-center shadow-lg min-h-358',
    applicationCardCenter: 'text-center shadow-lg min-h-310',
    applicationCardLeft: 'text-left shadow-lg min-h-310',
    applicationDescription: 'text-left text-normal',
    applicationSuperpass: 'text-left text-normal text-cyan-500',
    nftStatCenter: 'text-center',
    nftStatName: 'p0 font-semibold',
    statCard: 'shadow-lg',
    applicationImage: 'max-h-40 mx-auto',
    badgeUL: 'absolute left-1 top-1 text-xxs bg-slate-500 text-white',
    badgeUR: 'absolute right-1 top-1 text-xxs bg-slate-500 text-white',
    buttonURRed: 'absolute right-1 top-1 text-xxs bg-red-500 text-white px-2',
    centerButton: 'mx-auto text-center',
    smallRow: 'p0 text-s font-semibold text-center bg-white shadow-lg px-3 py-2 mb-2',
    tinyRow: 'p0 text-s font-semibold text-center bg-white shadow-lg px-3 py-0.5 mb-2',
    
  }
  
  export default Style;