/***
*
*   ONBOARDING
*   Flow to help users set up the app, accepts multiple views/steps 
*   On finish/cancel, the user will be marked as onboarded
*
*   PROPS
*   views: array of views to render containing keys: 
*     name, description and component to render
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import { Animate, CheckList, Button, ViewContext, Logo, useNavigate, Event, Card, Link, Grid, TabView, Search, Form, Switch } from 'components/lib';

import { Carousel } from 'react-responsive-carousel';
import Style from './battlefield.tailwind.js';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
export function BossConfig(props){
  //const navigate = useNavigate();
  //const hash = window.location.hash;
  const viewContext = useContext(ViewContext);
  const battlefieldId = props.nftData.id;
  const [bossId, setBossId] = useState(props.customizations?.find(customization => customization.c_key === "Battlefield")?.boss_id);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  //const [formData, setFormData] = useState(null);
  //const [battlefieldLink, setBattlefieldLink] = useState(null);
  const [waves, setWaves] = useState([]);
  const [currentWaveIndex, setCurrentWaveIndex] = useState(0);
  const [enemyTypeCounts, setEnemyTypeCounts] = useState({
    Minion: 0,
    Warrior: 0,
    Mage: 0,
    Healer: 0,
    Beast: 0
  });
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionTokens, setCollectionTokens] = useState([]);
  const [selectedCollectionToken, setSelectedCollectionToken] = useState(null);
  const [selectedSuperpassCollectionToken, setSelectedSuperpassCollectionToken] = useState(null);
  const [selectedSuperpassIndex, setSelectedSuperpassIndex] = useState(-1);
  const [baseItemTokens, setBaseItemTokens] = useState(null);
  const [itemTokens, setItemTokens] = useState(null);
  const [bossToken, setBossToken] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [bossOptions, setBossOptions] = useState([]);
  const [loadState, setLoadState] = useState(true);
  const [userCustomizations, setUserCustomizations] = useState([]);

  const difficultyMultipliers = {
    'Easy': 0.5,
    'Normal': 1,
    'Hard': 1.5,
    'Elite': 3,
    'Boss': 10
  };

  const enemyTypes = [
    {name: 'Minion', difficulty: 0.5},
    {name: 'Warrior', difficulty: 1},
    {name: 'Mage', difficulty: 2},
    {name: 'Healer', difficulty: 3},
    {name: 'Beast', difficulty: 4},
  ];

  //const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');


  const forceUpdate = () => {
    setCount(prevCount => prevCount + 1); // Increment count to force re-render
  };

  const forceUpdate1 = () => {
    setCount1(prevCount => prevCount + 1); // Increment count to force re-render
  };

  useEffect(() => {
    //console.log("Updated itemTokens:", itemTokens);
  }, [itemTokens]);

  useEffect(() => {
    //console.log("Updated bossOptions:", bossOptions);
    //console.log("itemTokens");
    //console.log(itemTokens);

    let superpassCustomization = bossOptions["Superpass"];
    if(superpassCustomization){
      let foundToken = itemTokens.artifacts.find(sp=>sp.id===superpassCustomization);
      if(foundToken){
        setSelectedSuperpassCollectionToken();
        setSelectedSuperpassIndex(itemTokens.artifacts.findIndex(sp=>sp.id===superpassCustomization));
      } else {
        setSelectedSuperpassIndex(-1);
      }
    }
  }, [bossOptions, itemTokens]);

  useEffect(() => {
    console.log("Initial load boss");
    //console.log(props);

    async function getItemCollections() {
      const res = await Axios.post("api/user/collection",{
        collection: ["lootshop","superpass"],
        group_by_type: true
      });
      console.log("Items")
      //console.log(res.data.data.inventory);
      setBaseItemTokens(res.data.data.inventory);
      setItemTokens(res.data.data.inventory);


      const res2 = await Axios.get("api/customization");
      console.log("User Customizations")
      console.log(res2.data.data);
      setUserCustomizations(res2.data.data);
      //setItemTokens(res.data.data.inventory);

      //forceUpdate();
      //return res.data.data.inventory;
    }

    async function getBoss(bossId) {
      
      await getItemCollections();
      let theCollections = await getCollections();

      const res = await Axios.get(`api/nft/${bossId}`);
      console.log(res.data.data);
            
      let selectedContract = theCollections.find(collection => collection.address.toLowerCase() === res.data.data.nftData.asset_contract.toLowerCase());
      console.log("Selected Contract");
      console.log(selectedContract);
      let bossData = res.data.data.nftData;
      setSelectedCollectionToken(bossData);

      //This kicks off a token retrieval
      setSelectedCollection(selectedContract.address);

      await getBossCustomizations(bossId);
      setBossToken(bossData)
      forceUpdate1();
      setLoadState(false);
      
    }

    async function getCollections() {
      const res = await Axios.post("api/user/collections",{
        min_level: 100
      });
      console.log(res.data.data);
      setCollections(res.data.data.collections);
      //forceUpdate();
      return res.data.data.collections;
    }

    
    if(bossId){
      console.log("Existing Boss Id: " + bossId);
      getBoss(bossId);

    } else {
      console.log("No Existing Boss Id: " + bossId);
      getItemCollections();
      getCollections();
      setLoadState(false);
    }
    
  }, []);

 

  useEffect(() => {

    if(!selectedCollection) return;
    console.log(`Fetching collection Tokens for ${selectedCollection}`);
    console.log(`Existing selected token`);
    console.log(`Existing selected token: ${bossId}`);
    
    if(!loadState){
      console.log(`LoadState: ${loadState}`);
      setBossToken(null);
      setSelectedCollectionToken(null);
      setSelectedSuperpassCollectionToken(null);
    }
    
    //console.log(props);
    async function getCollectionNFTs() {
      const res = await Axios.post("api/user/collection/tokens",{
        collection_address: selectedCollection,
        min_level: 75
      });
      console.log(res.data.data);
      
      setCollectionTokens(res.data.data.collectionTokens);
      let findBossIndex = res.data.data.collectionTokens.findIndex(token => token.id === bossId);
      if(findBossIndex > -1){
        setSelectedItemIndex(findBossIndex);
      } else {
        setSelectedItemIndex(0);
      }
      
      forceUpdate();
    }

    getCollectionNFTs();
  }, [selectedCollection]);


  const saveConfiguration = () => {
    // Your saving logic here
    console.log("Sending to server:", JSON.stringify(waves));
  };

  const currentWave = waves[currentWaveIndex] || {};

  const collectionCallback = (newValue) => {
    //console.log(`Collection Callback ${newValue}`)
    setSelectedCollection(newValue);
  }

  const collectionTokenCallback = (newValue) => {
    console.log(`Collection Token Callback ${newValue}`)
    setSelectedCollectionToken(newValue);
  }

  const superpassTokenCallback = (newValue) => {
    //console.log(`Collection Token Callback ${newValue}`)
    setSelectedCollectionToken(newValue);
  }

  const bossOptionsCallback = async (slot, newValue, slotType = "equipment") => {
    console.log(`bossOptionsCallback ${slot} ${newValue}`)
    let theBossOptions = bossOptions;
    theBossOptions[slot] = newValue;
    setBossOptions(theBossOptions);
    console.log(bossOptions);

    //setSelectedCollection(newValue);
    let customizationData = {
      bossId: selectedCollectionToken.id,
      customizationType: slotType,
      customizationKey: slot,
      customizationData: newValue, 
      
    }
    const res = await Axios.post("api/customization", customizationData);
    //console.log(res.data.data);
    viewContext.notification.show(`Saved ${slot} customization`, 'success', true);


  }

  const getFormData = () => {
    //console.log(`Refreshing Form Data`);
    let formData = {
      collection: {
        //label: '',
        small: true,
        type: 'select',
        required: false,
        default: selectedCollection,
        callback: collectionCallback,
        placeholder: 'Choose a collection',
        options: collections.map((x, index) => { return {
          id: x.value, 
          key: `collection-${index}`, 
          label: `[${x.chain}] ${x.name}`,
          value: `${x.address}`
        }})
      }
    };

    if(collectionTokens.length > 0 & 1===3){
      //console.log(`Selected Collection: ${selectedCollection} Listing Tokens`);
      //console.log(collectionTokens);
      formData.boss = {
        label: 'Choose Boss',
        type: 'select',
        required: true,
        default: selectedCollectionToken,
        callback: collectionTokenCallback,
        placeholder: 'Choose a boss',
        options: collectionTokens.map((x, index) => { 
          //console.log(x);
          let tokenName = x.nickname ? `${x.nickname} (${x.name})` : x.name;
          
          return {
            key: `collection-token-${index}`, 
            label: `${tokenName} Level: ${x.level}`,
            value: `${x.id}`
        }})
      }
    }
    
    return formData;
  }

  const saveBossOptions =  async () => {
    console.log(`saveBossOptions`)
    console.log(bossOptions)
    console.log(selectedCollectionToken);
    
  }

  const assignBoss = async (skipData = false) => {
    console.log(`assignBoss`)
    console.log(selectedCollectionToken)
    
    if(!skipData){
      let customizationData = {
        bossId: selectedCollectionToken.id,
        customizationType: "boss",
        customizationKey: "Battlefield",
        customizationData: battlefieldId, 
        
      }
      const res = await Axios.post("api/customization", customizationData);
      //console.log(res.data.data);
      viewContext.notification.show(`Boss Assigned`, 'success', true);
    }
    

    await getBossCustomizations(selectedCollectionToken.id);
        
    setBossToken(selectedCollectionToken)
    setBossId(selectedCollectionToken.id);
    forceUpdate1();
  }

  async function getBossCustomizations(bossId){
    console.log(`getBossCustomizations`)
    //This might come back with existing customizations for this boss
    const res = await Axios.get(`api/customization/boss/${bossId}`);
    console.log(res.data.data);

    let existingCustomizations = res.data.data;
    let theBossOptions = [];
    
    existingCustomizations.forEach((customization, index) => {
      theBossOptions[customization.c_key] = customization.c_data;
    })
    setBossOptions(theBossOptions);
    forceUpdate1();
  }

  async function onBossChange(index, item) {
    setSelectedCollectionToken(collectionTokens[index]);
  }

  async function onBossClickItem(index, item) {
    setSelectedCollectionToken(collectionTokens[index]);
    if(bossId === collectionTokens[index].id){
      setBossToken(collectionTokens[index]);
    }
  }

  async function onBossClickThumb(index, item) {
    setSelectedCollectionToken(collectionTokens[index]);
  }

  async function onSuperpassChange(index, item) {
    if(item.key !== "superpass-none"){
      setSelectedSuperpassCollectionToken(itemTokens?.artifacts[index]);
      //await setSuperpass(itemTokens?.artifacts[index])
    }
  }

  async function onSuperpassClickItem(index, item) {
    if(item.key !== "superpass-none"){
      setSelectedSuperpassCollectionToken(itemTokens?.artifacts[index]);
    }
  }

  async function onSuperpassClickThumb(index, item) {
    if(item.key !== "superpass-none"){
      setSelectedSuperpassCollectionToken(itemTokens?.artifacts[index]);
      //await setSuperpass(itemTokens?.artifacts[index])
    }
  }

  async function setSuperpass(token){
    if(!token){return}
    let theBossOptions = bossOptions;
    theBossOptions["Superpass"] = token.id;
    setBossOptions(theBossOptions);
    let customizationData = {
      bossId: selectedCollectionToken.id,
      customizationType: "equipment",
      customizationKey: "Superpass",
      customizationData:  token.id, 
      
    }
    const res = await Axios.post("api/customization", customizationData);
    //console.log(res.data.data);
    viewContext.notification.show(`Saved Superpass customization`, 'success', true);
  }

  function getItemString(x){
    let traitDesc = "";
    let tokenName = x.nickname ? `${x.nickname} (${x.name})` : x.name;
    let tokenDesc = `${tokenName} [${x.itemSubType}]`
    let tokenType = x.itemType;
    switch (tokenType.toLowerCase()) {
      case "weapon":{
        const qualityTrait = x.traits.find(t => t.trait_type === "Quality")?.value;
        const damageTrait = x.traits.find(t => t.trait_type === "Damage")?.value;
        const speedTrait = x.traits.find(t => t.trait_type === "Speed")?.value;
        const elementTrait = x.traits.find(t => t.trait_type === "Element")?.value;
        const enchantmentTraits = x.traits.filter(t => t.trait_type === "Enchantment");
        //console.log(enchantmentTraits);
        const enchantmentString = enchantmentTraits?.length > 0 ? "Ench: " + enchantmentTraits.map(enchantment => enchantment.value).join(', ') : "";
        //console.log(enchantmentString);
        traitDesc = `${elementTrait? elementTrait : ""} Dmg:[${damageTrait}] Spd:${speedTrait} Qual: ${qualityTrait}  ${enchantmentString}`;
        }
        break;
      case "armor":{
        const armorTrait = x.traits.find(t => t.trait_type === "Armor Level")?.value;
        const qualityTrait = x.traits.find(t => t.trait_type === "Quality")?.value;
        const setTrait = x.traits.find(t => t.trait_type === "Set Name")?.value;
        const guildTrait = x.traits.find(t => t.trait_type === "Guild")?.value;
        const enchantmentTraits = x.traits.filter(t => t.trait_type === "Enchantment");
        //console.log(enchantmentTraits);
        const enchantmentString = enchantmentTraits?.length > 0 ? "Ench: " + enchantmentTraits.map(enchantment => enchantment.value).join(', ') : "";
        //console.log(enchantmentString);
        traitDesc = `AL: ${armorTrait} ${setTrait !== "None" ? `Set: ${setTrait}` : ""} ${guildTrait !== "None" ? `Guild: ${guildTrait}` : ""} Qual: ${qualityTrait} ${enchantmentString}`;
        tokenDesc = `${tokenName} `
        }
        break;
      default:
        break;
    }

    return {
      traitDesc: traitDesc,
      tokenName: tokenName,
      tokenDesc: tokenDesc,
    }
  }

  const getBossFormData = () => {
    const strategies = [
      {
        name: "Warrior",
        type: "warrior",
      },
      {
        name: "Mage",
        type: "mage",
      },
      {
        name: "Random",
        type: "random",
      }
    ]
    //console.log(`Refreshing Boss Form Data`);
    let formData = {
      strategy: {
        label: "Combat Class",
        type: 'select',
        small: true,
        required: false,
        default: bossOptions["CombatClass"] ? bossOptions["CombatClass"] : "random",
        callback: (e)=>{bossOptionsCallback("CombatClass", e, "setting")},
        placeholder: 'Choose a combat class',
        options: strategies.map((x, index) => { return {
          id: x.value, 
          key: `strategy-${index}`, 
          label: `${x.name}`,
          value: `${x.type}`
        }})
      }
    };

    if(itemTokens?.weapons?.length > 0){
      //console.log(`Selected Collection: ${selectedCollection} Listing Tokens`);
      //console.log(collectionTokens);
      formData.weapon = {
        label: 'Primary Weapon',
        type: 'select',
        required: false,
        small: true,
        default: bossOptions["Weapon"] ? bossOptions["Weapon"] : "random",
        callback: (e)=>{bossOptionsCallback("Weapon", e)},
        placeholder: 'Choose a weapon',
        options: itemTokens.weapons.map((x, index) => { 
          let usedItem = userCustomizations.find(customization => customization.c_data === x.id);
          let usedItemString = usedItem ? " (Used) " : "";
          let {traitDesc, tokenName, tokenDesc} = getItemString(x);
          //console.log(x);
          return {
            key: `weapon-token-${index}`, 
            label: `${usedItemString}${tokenDesc} Lvl: ${x.level} ${traitDesc}`,
            value: `${x.id}`
        }})
      }

      formData.weapon.options.unshift({
        key: `weapon-token-none`,
        label: `None`,
        value: `none`
      })
      formData.weapon.options.unshift({
        key: `weapon-token-random`,
        label: `Random`,
        value: `random`
      })
    }

    const uniqueArmorTypes = [
      "Head",
      "Shoulders",
      "Hands",
      "Chest",
      "Bracers",
      "Legs",
      "Belt",
      "Feet",
    ]

    if(itemTokens?.armor?.length > 0){
      //const uniqueArmorTypes = [...new Set(itemTokens.armor.map(item => item.itemSubType))];
      //console.log(uniqueArmorTypes);
      uniqueArmorTypes.forEach((armorType, index) => {
        //console.log(armorType);
        formData[`armor-${index}`] = {
          label: `${armorType} Armor`,
          type: 'select',
          small: true,
          required: false,
          default: bossOptions[armorType] ? bossOptions[armorType] : "random",
          callback: (e)=>{bossOptionsCallback( armorType, e)},
          placeholder: `Choose an armor piece`,
          options: itemTokens.armor.filter(x => x.itemSubType === armorType).map((x, index) => {
            //console.log(x);
            let usedItem = userCustomizations.find(customization => customization.c_data === x.id);
            let usedItemString = usedItem ? " (Used) " : "";
            let {traitDesc, tokenName, tokenDesc} = getItemString(x);
            return {
              key: `armor-token-${index}`, 
              label: `${usedItemString}Lvl: ${x.level} ${traitDesc} ${tokenDesc}`,
              value: `${x.id}`
          }})
        }

        formData[`armor-${index}`].options.unshift({
          key: `armor-token-random`,
          label: `Random`,
          value: `random`
        })
        formData[`armor-${index}`].options.unshift({
          key: `armor-token-none`,
          label: `None`,
          value: `none`
        })
      })
    }

    //console.log(formData);
    return formData;
  }

  async function changeItemFilter(name, state, state2){
    console.log(name, state, state2);
    let theTokens = baseItemTokens;
    
    const res2 = await Axios.get("api/customization");
    console.log("User Customizations")
    console.log(res2.data.data);
    setUserCustomizations(res2.data.data);

    let usedTokens = [];
    res2.data.data.forEach(customization => {
      if(customization.c_type === "equipment"){
        usedTokens.push(customization.c_data);
      }
    });

    if(!state){
      theTokens.artifacts = theTokens.artifacts.filter(token => !bossOptions.find(option=>option.c_data === token.id) && !usedTokens.includes(token.id));
      theTokens.weapons = theTokens.weapons.filter(token => !bossOptions.find(option=>option.c_data === token.id) &&!usedTokens.includes(token.id));
      theTokens.armor = theTokens.armor.filter(token => !bossOptions.find(option=>option.c_data === token.id) &&!usedTokens.includes(token.id));
    }
    
    setItemTokens(theTokens);
    forceUpdate1();
    
    //setAdvanced(state);
    //viewContext.setAdvanced(state);
  }

  return (
    <Animate type='pop-center'>
      <Grid cols="3">
        <div className={ 'px-0' }>
          <Card
            dark
            className={Style.smallRow} 
            center
            title="Boss Selection"
            smallHeader
          >
            <p>Assign a Boss (lvl 100+) from your collections</p>
            <div className='mx-auto max-w-sm'>
            {collections.length > 0 && (
              <Form
                key={count}
                data={getFormData()}
                //url='/api/account/plan'
                //method='PATCH'
                //updateOnChange={true}
                //buttonText='Assign Boss'
                /*callback={(res) => {

                  //context.update({ plan: res.data.data.plan });

                }}*/
              />
              )}
              <div className='mx-auto m-auto text-center py-2'>
                {collectionTokens && 
                  <Carousel
                    key={count}
                    centerMode={true}
                    width={"100%"}
                    onClickItem={onBossClickItem}
                    onClickThumb={onBossClickThumb}
                    onChange={onBossChange}
                    centerSlidePercentage={100}
                    thumbWidth={90}
                    selectedItem={selectedItemIndex}
                  >
                    {collectionTokens && collectionTokens.map((nft, index) => {
                      return (
                        <div key={nft.id}>
                        <img src={`${nft.image_url}`} alt={`${nft.image_url}`}/>
                          <p >{`${nft.name} ${nft.nickname? `"${nft.nickname}"`:""} Level: ${nft.level}`}</p>
                        </div>
                      )
                      })
                    }
                  </Carousel>
                }
              </div>

              {selectedCollectionToken && (selectedCollectionToken.id !== bossId) && (
                <div>
                  <Button
                    className={Style.centerButton}
                    action={() => assignBoss()}
                    text="Assign Boss"
                  />
                </div>
              )}
              {selectedCollectionToken && (selectedCollectionToken.id === bossId) && (
                <div>
                  <p className='text-green-500'>Currently Assigned Boss</p>
                </div>
              )}
            </div>
          </Card>
        </div>
        {bossToken && 
          <div className={ 'px-0' }>
            <Card 
              dark
              className={Style.smallRow } 
              center
              smallHeader
            >
              {!bossToken && 
                <>
                  <p>Assign a Boss to the Battlefield to configure it.</p>
                </>
              }
              {bossToken && 
                <>
                  <p>Boss: {`${bossToken.name}`}<br/>
                  <small>{`${bossToken.id}`}</small>
                  </p>
                  <div className='mx-auto '>
                    <small>Assign Items and Artifacts to this Boss</small>
                    <div className='mx-auto m-auto text-left max-w-md'>
      
                      {/*<Switch 
                        small
                        label= {'Show All Items (not just unassigned)'}
                        name= 'BasicToggle'
                        default= {true}
                        onChange={ (name, state, state2) => changeItemFilter(name, state, state2)}
              /> */} 
      
                      <Form
                        key={count1}
                        data={getBossFormData()}
                        //url='/api/account/plan'
                        //method='PATCH'
                        //updateOnChange={true}
                        //buttonText='Assign Boss'
                        /*callback={(res) => {

                          //context.update({ plan: res.data.data.plan });

                        }}*/
                      />
                    </div>

                    {/*SUPERPASSES */}
                    <div className='mx-auto m-auto text-center max-w-sm'>
                      <br/><p>Choose a Superpass</p>
                      {itemTokens?.artifacts && itemTokens?.artifacts.length === 0 && (
                        <div className='text-rose-500'>
                          <small className='text-red-500'>You do not own any Superpass Artifacts</small>
                          <Button
                            className={Style.centerButton}
                            action={() => assignBoss()}
                            text="Buy a Superpass"
                            small
                          />
                        </div>
                      )}
                      {itemTokens?.artifacts && itemTokens?.artifacts.length > 0 && (
                        <>
                          <Carousel
                            centerMode={true}
                            width={"90%"}
                            onClickItem={onSuperpassClickItem}
                            onClickThumb={onSuperpassClickThumb}
                            onChange={onSuperpassChange}
                            centerSlidePercentage={100}
                            thumbWidth={100}
                            selectedItem={selectedSuperpassIndex > -1 ? selectedSuperpassIndex : itemTokens.artifacts.length}
                          >
                            {itemTokens?.artifacts && itemTokens?.artifacts.map((nft, index) => {
                              return (
                                <div key={nft.id}>
                                  <img src={`${nft.image_url}`} alt={`${nft.image_url}`}/>
                                  <small >{`${nft.name} ${nft.nickname? `"${nft.nickname}"`:""} Level: ${nft.level}`}</small>
                                </div>
                              )
                              })
                            }
                            <div key={'superpass-none'}>
                              <img alt="none" src='https://nftscribe.s3.amazonaws.com/media/null.png' />
                              <small >None</small>
                            </div>
                          </Carousel>
                          {selectedSuperpassCollectionToken && (bossOptions["Superpass"] !== selectedSuperpassCollectionToken.id) && (
                            <div>
                              <Button
                                className={Style.centerButton}
                                action={async () => await setSuperpass(selectedSuperpassCollectionToken)}
                                text="Assign Superpass"
                              />
                            </div>
                            
                          )}
                          {selectedSuperpassCollectionToken && (bossOptions["Superpass"] === selectedSuperpassCollectionToken.id) && (
                            <div>
                              <p className='text-green-500'>Currently Assigned Superpass</p>
                            </div>
                            
                          )}
                          {!selectedSuperpassCollectionToken && (
                            <div>
                              <p className='text-green-500'>Currently Assigned Superpass</p>
                            </div>
                            
                          )}
                        </>
                      )}
                      
                    </div>


                  </div>
              </>
              }
              {selectedCollectionToken && 1===3 && (
                <div>
                  <Button
                    className={Style.centerButton}
                    action={() => saveBossOptions()}
                    text="Save Options"
                  />
                </div>
                
              )}

            </Card>
          </div>
        }
        {bossToken && 
          <div className={ 'px-0' }>
            <Card 
              dark
              className={Style.smallRow} 
              center
              title="Customizations"
              smallHeader
            >
              {!bossToken && 
                <>
                  <p>Assign a Boss to the Battlefield to configure it.</p>
                </>
              }
              {bossToken && 
              <>
                <small>Enter one or more lines, hitting enter in between</small><br/>
                <Form 
                  data={{
                    boss_id: {
                      type: 'hidden',
                      value: `${bossId}`
                    },
                    dialog_taunts: {
                      label: `Dialog (Taunts)`,
                      type: 'textarea',
                      class: `text-slate-900 text-sm`,
                      value: bossOptions["dialog_taunts"] ? bossOptions["dialog_taunts"] : "",
                    },
                    dialog_defeat: {
                      label: `Dialog (Defeat)`,
                      type: 'textarea',
                      class: `text-slate-900 text-sm`,
                      value: bossOptions["dialog_defeat"] ? bossOptions["dialog_defeat"] : "",
                    },
                    dialog_death: {
                      label: `Dialog (Death)`,
                      type: 'textarea',
                      class: `text-slate-900 text-sm`,
                      value: bossOptions["dialog_death"] ? bossOptions["dialog_death"] : "",
                    },
                    dialog_introduction: {
                      label: `Dialog (Villain Speech)`,
                      type: 'textarea',
                      class: `text-slate-900 text-sm`,
                      value: bossOptions["dialog_introduction"] ? bossOptions["dialog_introduction"] : "",
                    }
                  }}
                  method='POST'
                  url='/api/customization/dialog'
                  //updateOnChange
                  className={ Style.form }
                  buttonText='Save Dialog'
                  //onChange={ e => { return false }}
                  callback={ e => {

                    //setShowForm(false);
                    viewContext.notification.show(`Saved Dialog customizations`, 'success', true);
                    //viewContext.notification.show('Thank you, your feedback is most appreciated.', 'success', true, 'toast', 'heart');

                  }}
                />
              </>
              }
            </Card>
          </div>
        }
      </Grid>
    </Animate>
  );
}