import { NFTList } from 'views/nft/nftlist';
import { NFTCollectionList } from 'views/nft/nft_collection_list';
import { NFTCollectionView } from 'views/nft/nft_collection_view';
import { NFTUserView } from 'views/nft/nft_user_view';
import { NFTViewHome } from 'views/nft/viewer/index';
import { NFTHolderHome } from 'views/nft/holder/index';
import { NFTHolderEvents } from 'views/nft/holder/events';
import { NFTViewerEvents } from 'views/nft/viewer/events';
import { NFTHolderConfig } from 'views/nft/holder/config';
import { CollectionGameDashboard } from 'views/nft/game_dashboard';
import { GameDashboard } from 'views/game/game_dashboard';
import { GamesDashboard } from 'views/game/games_dashboard';


const Routes = [
  {
    path: '/collectibles',
    view: NFTList,
    layout: 'appDark',
    //permission: 'user',
    title: 'MyStuff'
  },
  {
    path: '/nft/holder/:nftid',
    view: NFTHolderHome,
    layout: 'appDark',
    permission: 'user',
    title: 'Collectible Details'
  },
  {
    path: '/nft/config/:nftid',
    view: NFTHolderConfig,
    layout: 'appDark',
    permission: 'user',
    title: 'Collectible Customization'
  },
  {
    path: '/nft/events/:nftid',
    view: NFTHolderEvents,
    layout: 'appDark',
    permission: 'user',
    title: 'Collectible Details'
  },
  {
    path: '/user/:userid',
    view: NFTUserView,
    layout: 'appDark',
    //permission: 'user',
    title: 'User Profile'
  },
  {
    path: '/u/:userid',
    view: NFTUserView,
    layout: 'appDark',
    //permission: 'user',
    title: 'User Profile'
  },
  {
    path: '/nft/view/:nftid',
    view: NFTViewHome,
    layout: 'appDark',
    //permission: 'user',
    title: 'Collectible Details'
  },
  {
    path: '/nft/view/events/:nftid',
    view: NFTViewerEvents,
    layout: 'appDark',
    //permission: 'user',
    title: 'Collectible Events Details'
  },
  {
    path: '/communities',
    view: NFTCollectionList,
    layout: 'home',
    //permission: 'user',
    title: 'Communities'
  },
  {
    path: '/community/:collectionid',
    view: NFTCollectionView,
    layout: 'home',
    //permission: 'user',
    title: 'Collection'
  },
  {
    path: '/community/game/:collectionid',
    view: CollectionGameDashboard,
    layout: 'home',
    //permission: 'user',
    title: 'Gaming Dashboard'
  },
  {
    path: '/game/:gameid',
    view: GameDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Game Dashboard'
  },
  {
    path: '/games',
    view: GamesDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Active Conquest Games'
  },

  
]

export default Routes;
