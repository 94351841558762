const Style = {
    collection: 'p0 text-xs text-normal-tight',
    collectionTitle: 'p0 text-s text-normal-tight font-semibold leading-4 text-center',
    collectionTitleDark: 'p0 text-xs text-normal-tight font-semibold leading-4 text-center text-slate-600',
    collectionStatsBoxDark: 'p0 text-xs text-normal-tight font-semibold text-center text-slate-100 min-h-50 leading-4 bg-slate-700 shadow-lg border-emerald-400 border-1 rounded border-solid',
    inscribeAllBtn: 'inline text-center font-semibold rounded px-12 py-3 mx-4 ease-in-out duration-500 text-white',
    nftname: 'p0 text-xs font-semibold',
    subtext: 'p0 text-xs font-semibold bg-orange-500 text-white',
    nft: 'mx-auto text-center min-h-400 shadow-lg',
    centerButton: 'mx-auto',

    nftNoButton: 'mx-auto text-center min-h-280 shadow-lg',
    nftCollectionStatsCard: 'mx-auto text-center min-h-230 shadow-lg',
    nftLockedSelected: 'mx-auto text-center min-h-345 shadow-lg bg-emerald-100',
    nftLocked: 'mx-auto text-center min-h-345 shadow-lg',
    nftholder: 'mx-auto text-center min-h-390 shadow-lg',
    userCardName: 'mx-auto text-left min-h-100 shadow-lg bg-slate-800 text-cyan-400 text-5xl align-middle',
    userCardPlatformName: 'mx-auto text-left shadow-lg bg-slate-800 text-cyan-400 text-xs align-middle',
    userCardStat: 'mx-auto text-center min-h-90 shadow-lg bg-slate-900 border-green-400 border-2',
    statCardOverflow: 'mx-auto text-center min-h-390 shadow-lg overflow-y-scroll',
    nftCollectionCard: 'mx-auto text-center min-h-275 shadow-lg',
    nftStats: 'mx-auto min-h-390 shadow-lg',
    nftButton: 'px-5 py-1!important mx-auto text-center mt-1',
    nftLeftButton: 'px-5 py-1!important text-center mt-1',
    nftUpgradeButton: 'px-5 py-1!important mx-auto text-center mt-1 min-w-230',
    noBottomMargin: 'mb-0!important',
    communityStat: '!max-w-xs w-36 mb-0 !p-0',
 
    

    
    nftImage: 'cursor-pointer max-h-80 sm:max-h-52 mx-auto',
    nftImageSmall: 'cursor-pointer max-h-80 sm:max-h-40 mx-auto',
    nftImageLarge: 'cursor-pointer max-h-250 md:max-h-250 sm:max-h-52 mx-auto',
    nftCollectionImage: 'max-h-32 mx-auto',
    nftCollectionImageSmall: 'max-h-100 mx-auto',
    nftCollectionImageMedium: 'max-h-200 mx-auto',
    nftStatCardCenter: 'text-center shadow-lg min-h-250 max-h-345',
    nftStatCardCenterOverflow: 'text-center shadow-lg min-h-525 min-w-320 max-h-310 overflow-y-scroll',

    nftStatCenter: 'text-center text-xs',
    nftStatName: 'p0 font-semibold',

    statName: 'text-cyan-400 font-semibold',
    statCard: 'shadow-lg',
    darkBack: 'bg-slate-800',
    overflowCard: 'overflow-y-auto',
    applicationImage: 'max-h-40 mx-auto',
    signatureImage: 'max-h-40 mx-auto border-solid border border-slate-200 p-3',
    
    smallRow: 'p0 text-s font-semibold text-center bg-white shadow-lg px-3 py-2 mb-2',
    tinyRow: 'p0 text-s font-semibold text-center bg-white shadow-lg px-3 py-0.5 mb-2',

    walletButton1: 'absolute text-center left-30 z-10',
    walletButton2: 'absolute text-center left-200 z-10',
    
  }
  
  export default Style;