const Style = {

  switch: 'relative block w-full mb-6',
  switchSmall: 'relative w-full mb-6',
  switchInline: 'relative mb-1',
  label: 'absolute left-20 top-1 normal-case',
  labelSmall: 'absolute left-10 top-1 normal-case leading-3',
  labelNormal: 'absolute left-20 top-1 normal-case',
  track: 'absolute top-0 left-0 w-14 h-8 cursor-pointer overlow-hidden rounded-full ease-in-out duration-300',
  trackSmall: 'absolute top-0 left-0 w-9 h-5 cursor-pointer overlow-hidden rounded-full ease-in-out duration-300',
  trackOn: 'bg-emerald-500',
  trackOnSmall: 'bg-emerald-500',
  trackOff: 'bg-slate-300',
  trackOffSmall: 'bg-slate-300',
  handle: `absolute top-1/2 text-white h-6 w-6 text-xs text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,
  handleSmall: `absolute top-1/2 text-white h-4 w-4 text-xs text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,
    
  handleOff: 'left-1',
  handleOffSmall: 'left-1',
  handleOn: 'left-7',
  handleOnSmall: 'left-4',

}

export default Style;